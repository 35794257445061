import React from 'react';
import { Box, Card, Heading } from 'rebass';
import { useTranslation } from 'react-i18next';

import Page from '../../components/Page';
import WhyWeBoxitLayout from './_layout';
import { Hide } from '../../components/hide';
import { Container } from '../../components/container';
import {
  BoxContentTitle,
  ForCustomersBullets,
  ForCustomersBulletsMobile,
} from './for_customers_shared';

import ForCustomersWhiteTriangle from '../../icons/for-customers-white-triangle.svg';
import ForCustomersBackground from '../../images/for-customers@3x.jpg';

export const BoxContentListItem = ({ children }) => (
  <Box pb={['6px', '0px', '10px']}>
    <li>{children}</li>
  </Box>
);

export const BoxContentList = ({ title, children }) => (
  <Box ml={[0, '10%', '20%']}>
    <BoxContentTitle>{title}</BoxContentTitle>
    <Box
      width={[1, 0.8, 0.75]}
      ml={['-20px', '-17px', '-17px']}
      color="blues.peacock"
      fontSize={['16px', '18px', '20px']}
      textAlign="left"
    >
      <ul>{children}</ul>
    </Box>
  </Box>
);

const BoxContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box pt={['30px', '10px']} />
      <BoxContentList
        title={t('why_we_boxit.for_customers.paragraphs.0.title')}
      >
        {t('why_we_boxit.for_customers.paragraphs.0.bullets', {
          returnObjects: true,
        }).map((bullet, index) => (
          <BoxContentListItem key={'bullet' + (index + 1)}>
            {bullet}
          </BoxContentListItem>
        ))}
      </BoxContentList>

      <Box pt={['20px', '30px', '50px']} />

      <BoxContentList
        title={t('why_we_boxit.for_customers.paragraphs.1.title')}
      >
        {t('why_we_boxit.for_customers.paragraphs.1.bullets', {
          returnObjects: true,
        }).map((bullet, index) => (
          <BoxContentListItem key={'bullet' + (index + 1)}>
            {bullet}
          </BoxContentListItem>
        ))}
      </BoxContentList>
    </>
  );
};

const FourStepsTutorial = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <WhyWeBoxitLayout
      subtitle={t('why_we_boxit.for_customers.menu.title')}
      {...props}
    >
      <Page
        title={t('why_we_boxit.for_customers.page.title')}
        subtitle={t('why_we_boxit.for_customers.page.subtitle')}
      >
        <Heading
          fontSize={['28x', '56px', '66px']}
          marginY={['34px', '46px', '58px']}
          lineHeight={['34px', '60px', '70px']}
          letterSpacing={['-0.6px', '-0.9px']}
          color="blues.twilight"
        >
          {t('why_we_boxit.for_customers.header')}
        </Heading>
      </Page>

      {/* Mobile Only */}
      <Hide tablet desktop>
        <Container>
          <BoxContent />
        </Container>
        <ForCustomersBulletsMobile />
      </Hide>
      {/* Tablet & Desktop */}
      <Hide mobile>
        <Card
          backgroundImage={'url(' + ForCustomersBackground + ')'}
          backgroundSize={['', 'cover']}
          backgroundPosition={['', 'center', 'right']}
          backgroundRepeat="no-repeat"
          css={{ maxWidth: '1440px' }}
          mx="auto"
        >
          <Card
            width={[0, 0.5, 0.5]}
            backgroundImage={'url(' + ForCustomersWhiteTriangle + ')'}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="right center"
          >
            <Box marginY="auto" py={[0, '50px', '80px']}>
              <BoxContent />
            </Box>
          </Card>
        </Card>
        <Container>
          <ForCustomersBullets />
        </Container>
      </Hide>
    </WhyWeBoxitLayout>
  );
};

export default FourStepsTutorial;
